import * as SignalR from "@microsoft/signalr";

import ActionsConstants from "./ActionsConstants";

export interface IErrorDelegate{
    ():void
}

export interface INotifyDelegate {
    (notify : string, /*connection : Connection */) : void

}

class NewHttpClient extends SignalR.HttpClient{

        constructor(token : string){
            super();
            this.token = token;
        }

        private readonly token : string;

        public async send(request : SignalR.HttpRequest) : Promise<SignalR.HttpResponse> {
            request.headers = {
                "Bearer" :  this.token
            }
            
            return new SignalR.DefaultHttpClient(console).send(request);
        }
}

class BaseHubConnection{

    protected readonly hubConnection : SignalR.HubConnection;

    

    constructor(uriAddres : string, token : string){
        this.hubConnection = this.GetHub(uriAddres, token);
        this.hubConnection.onclose(() => {
            setTimeout(() => {this.OpenConnection()}, 400);
        })
    }

    
    

    protected GetHub(uriAddres : string, myToken:string) : SignalR.HubConnection{
        let optionsHeader : SignalR.MessageHeaders = {
            "Bearer" : myToken
        }
        return new SignalR.HubConnectionBuilder()
            .withAutomaticReconnect()
            .withUrl(uriAddres, 
                {
                    accessTokenFactory : () => myToken,
                    skipNegotiation : true,
                    transport: SignalR.HttpTransportType.WebSockets,
                    headers : optionsHeader
                })
            .build()
    }

    public async OpenConnection() : Promise<void> {
        return await this.hubConnection.start();
    }

    public async CloseConnection() : Promise<void>{
        return await this.hubConnection.stop();
    }

    

}

export default BaseHubConnection;