import BaseHubConnection from "./BaseHubConnection";
import ActionsConstants from "./ActionsConstants";

interface SetImage{
    (image : string) : void
}

class VideoHubConnection extends BaseHubConnection{

    constructor(uriAddres : string, token : string){
        super(uriAddres,token);
    }

    public GetStreamVideo(callback : SetImage) {
        this.hubConnection.on(ActionsConstants.downloadStream,(bytes : string) => {
            callback(bytes);
        })
    }

    public async DownloadImg(image : string) : Promise<void> {
        return await this.hubConnection.invoke(ActionsConstants.uploadStream, image);
    }  

}

export default VideoHubConnection;