import RequestSignature from "../RequestProvider/RequestSignature";

class ManageRecognitionConstants {

    public static reconnectConstant : RequestSignature = new RequestSignature("POST", "/api/v1.0/ManageRecognition/Reconnect");

    public static startConstant : RequestSignature = new RequestSignature("POST", "/api/v1.0/ManageRecognition/Start");

    public static stopConstant : RequestSignature = new RequestSignature("POST", "/api/v1.0/ManageRecognition/Stop");

    public static clearQueueConstant : RequestSignature = new RequestSignature("POST", "/api/v1.0/ManageRecognition/ClearQueue");
    
}

export default ManageRecognitionConstants;