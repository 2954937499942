import Dictionary from "../../Models/Collections/Dictionary";
import RequestSignature from "./RequestSignature"

class RequestSettings{
    
    public RequestSignature : RequestSignature;

    public readonly Queries : Dictionary<string, string>;

    public readonly Data : any | null;

    public readonly Parametrs : string = "";
    

    constructor(requestSignature : RequestSignature, queries : Dictionary<string, string>, data : any | null, paremetrs : string = ""){
        this.RequestSignature = requestSignature;
        this.Queries = queries;
        this.Data = data;
        this.Parametrs = paremetrs;
    }
}

export default RequestSettings;