import React, { CSSProperties, useEffect } from 'react';
import VideoCapture from "./Helpers/Video/VideoСapture";
import "./App.css";
import VideoHubConnection from './Hubs/VideoHubConnection';

import Preloader from "./Preloader/Preloader";
import PopUp from "./PopUp/PopUp";
import DescriptionBlock from "./DescriptionBlock/DescriptionBlock";

import IManageServices from "./Services/ManageRecognitionServices/IManageRecognitionServices";
import ManageServices from "./Services/ManageRecognitionServices/ManageRecognitionServices";
import RequestProvider from './Services/RequestProvider/RequestProvider';

import LogoPng from "./Media/cloud-ai_logo.jpg";
import MenuPng from "./Media/burger.png";


function App() {

  const authUrl = "https://my.cloud-ai.tech/?address=https://stream-recognition.cloud-ai.tech/";


  let tokenReg = /authToken=.+&exp/;

  let manageServices : IManageServices = new ManageServices(new RequestProvider());

  const getTokenFromURI = () => {
    let fullURI = window.location.href;
    let partsURI = fullURI.match(tokenReg);
    if(partsURI)
      return partsURI[0].replace("authToken=", "").replace("&exp", "");
    else
      return "";

  }

  const getToken = () => {
    let token = localStorage.getItem('token');
    if(token == null || token == undefined || token == ""){
      token = getTokenFromURI();
      if(token.trim() == "")
        window.location.href = authUrl;
      localStorage.setItem("token", token);

    }
    let tokenFromUri = getTokenFromURI();
    if(tokenFromUri.trim() != "")
      window.location.href = window.location.href.split("?")[0];
    return token;

  }

  const [isError, useisError] = React.useState(false);

  let errorStr = 'Could not start video source';

  let isFirstTry = sessionStorage.getItem("isFirst") ?? "1";

  let videoCapture : VideoCapture = new VideoCapture();

  let videoHub = new VideoHubConnection("https://recognition.cloud-ai.tech/video", getToken());



  const takePhoto = () => {


    let videoTag = document.getElementById("video") as HTMLVideoElement;
    let photo = videoCapture.GetPhotoFromVideoStream(videoTag);
    videoHub.DownloadImg(photo)
      .then(() => {
        setTimeout(takePhoto, 250);
      });
  }

  const error = (e : Error) => {
    errorStr = e.message;
    if(isFirstTry == "1")
      useisError(true);
  }

  useEffect(() => {
    manageServices.ClearQueue().then(_ => {
      startStream();

      let videoTag = document.getElementById("video") as HTMLVideoElement;
      videoCapture.VideoStreamToVideoTag(videoTag,error );
    });
  });

  window.onunload = () => {
    manageServices.ClearQueue();
  }


  const startStream = () => {
    videoHub.OpenConnection().then(() => {
      videoHub.GetStreamVideo(videoCapture.SetPhotoToVideoStream);

      takePhoto();
    })
    .catch((e : Error) => {
        localStorage.removeItem("token");
        window.location.href = authUrl;
    })

  }

  let popUp = null;

  if(isError) {
    popUp = <PopUp closePopUp = {() => {useisError(false); sessionStorage.setItem("isFirst", "0")}} text = {errorStr}/>
  }

  let content = (
      <div className="App">
      {popUp}
      <header className="header">
        <a href="https://cloud-ai.tech/" target = "_blank">
          <img src= {LogoPng} alt=""/>
        </a>
        <div id = "navMenu" className = "headerPoints">
          <div>Stream Video Recognition service</div>
          <a href="https://docs.cloud-ai.tech/" target = "_blank">Doc</a>
        </div>
        <div  className="buttonHeader">
          <img src={MenuPng} alt=""/>
        </div>

      </header>
      <div className="videoArea">
        <div className = "videoBlock">
          <video id="video"></video>
        </div>
        <div className="canvas">
          <canvas id="canvas">

          </canvas>
        </div>
        <div id="imgContainer">
          <img className = "outVideo" id="img"></img>
        </div>
      </div>
      <DescriptionBlock/>
      <footer className="footer">
         © {new Date().getFullYear()} Cloud-AI technology
      </footer>
    </div>
    );


  return content;
}

export default App;
