import IManageRecognitionServices from "./IManageRecognitionServices";
import ManageRecognitionConstants from "./ManageRecognitionConstants";

import RequestSettings from "../RequestProvider/RequestSettings";
import IRequestProvider from "../RequestProvider/IRequestProvider";

import Dictionary from "../../Models/Collections/Dictionary";

class ManageRecognitionServices implements IManageRecognitionServices {

    constructor(requestProvider : IRequestProvider) {
        this.requestProvider = requestProvider;
    }

    private readonly requestProvider : IRequestProvider;

    public async Reconnect() : Promise<void> {
        let requestSettings = new RequestSettings(ManageRecognitionConstants.reconnectConstant,
            new Dictionary(),null);

        await this.requestProvider.RequestWithResponceAsync(requestSettings);    
    }

    public async Stop() : Promise<void> {
        let requestSettings = new RequestSettings(ManageRecognitionConstants.stopConstant,
                            new Dictionary(),null);
        
        await this.requestProvider.RequestWithResponceAsync(requestSettings);

    }

    public async Start() : Promise<void> {
        let requestSettings = new RequestSettings(ManageRecognitionConstants.startConstant,
            new Dictionary(),null);

        await this.requestProvider.RequestWithResponceAsync(requestSettings);
    }

    public async ClearQueue() : Promise<void> {
        let requestSettings = new RequestSettings(ManageRecognitionConstants.clearQueueConstant,
            new Dictionary(),null);

        await this.requestProvider.RequestWithResponceAsync(requestSettings);
    }


}

export default ManageRecognitionServices;