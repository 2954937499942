import React from "react";
import "./PopUp.css";

interface IPopUp{
  text:string
  closePopUp():void
}



function PopUp(props:IPopUp){

  return(
    <div className="PopUpPrompt">
      <div className="PopUpInsidePrompt">
          <div className="bodyPopUpPrompt">
            {props.text}
          </div>
          <div className="footerPopUpPrompt">
            <div onClick={props.closePopUp}>OK</div>
      
          </div>
        </div>
      </div>
  )
}

export default PopUp;
 