import React from "react";

import "./DescriptionBlock.css";

interface IDescriptionBlock{

}

function DescriptionBlock(props : IDescriptionBlock){
    return(
        <div className="desBlock">
            <input className="hide" id="hd" type="checkbox"/>
            <label className="label" htmlFor="hd" >Description</label>
            <div className="innerText">
               <p>
                    <span className="font-bold">Stream Video Recognition Cloud-AI API </span> is an interface that allows you to recognize and detect certain objects in the video stream in real time. This interface opens up a number of possibilities for software developers to search for the presence or absence of real-time objects based on excellent artificial intelligence.
               </p>
               <p>
               <span className="font-bold">A few examples:</span>
               </p>
               <ul>
                   <li className="liFeatures"><span className="font-bold">Visual marketing:</span> Companies that conduct an effective advertising campaign and explore the interest and attractiveness for customers</li>
                   <li className="liFeatures"><span className="font-bold">Road traffic:</span> Know the traffic congestion on the video stream from the web cameras installed on the streets</li>
                   <li className="liFeatures"><span className="font-bold">Point of Sale:</span> Evaluate traffic near an existing or future point of sale to choose the best location</li>
                   <li className="liFeatures"><span className="font-bold">Operational control:</span> detect objects located in the perimeter, such as a car or house</li>
                   <li className="liFeatures"><span className="font-bold">Defect detection:</span> Continuous visual assessment of the quality of parts and products on the conveyor or at the workplace from web cameras</li>
                   <li className="liFeatures"><span className="font-bold">Illegal content:</span> Instantly check the video stream for illegal or prohibited content, such as on streaming sites</li>
               </ul>
               <p>
                    You are using demo access version of <span className="font-bold">Cloud-AI API</span> Free with limited traffic, to switch to the paid version after running out of free traffic click <span className="font-italic"> Profile {'->'} Add {'->'} Replenishment {'->'} Video Stream Recognition </span>. The tariff plan allows you to perform the required number of transactions, ensuring maximum data protection and priority execution of requests.
               </p>
               <p>
                    The documentation provided here applies to the <span className="font-bold">Cloud-AI API</span>, please note that you are using the correct authorization request.
               </p>
               <p>You can always contact us to <a href="mailto:support@cloud-ai.tech">support@cloud-ai.tech</a></p>
            </div>
        </div>)
}

export default DescriptionBlock;
