import imageCompression from "browser-image-compression";


interface ErrorCallback {
    (e : Error):void
}

interface ILoadImg {
    (image : string) : void
}

class VideoСapture {
    

    private readonly StreamOptions : MediaStreamConstraints = {
        audio : false,
        video : true
    }

    public VideoStreamToVideoTag(videoTag : HTMLVideoElement, errorCallback : ErrorCallback ) : void {
        navigator.mediaDevices.getUserMedia({ video: true, audio: false })
        .then(function(stream) {
            videoTag.srcObject = stream;
            videoTag.play();
        })
        .catch(function(err) {
            errorCallback.call(null, err);
        });
    }

    public SetPhotoToVideoStream(image : string) {
        let outImg = document.getElementById("imgContainer") as HTMLDivElement;
        let img = document.getElementById("img") as HTMLImageElement;
        img.src = "data:image/jpg;base64," + image;

    }


    public GetPhotoFromVideoStream(videoTag : HTMLVideoElement) : string{
        let canvas = document.createElement('canvas')
        let width = 321;
        let height = 220;
        const maxHeight = 10;
        const maxWidth = 320;

        if (width > height) {
            if (width > maxWidth) {
            height = Math.round((height *= maxWidth / width));
            width = maxWidth;
            }
        } else {
            if (height > maxHeight) {
            width = Math.round((width *= maxHeight / height));
            height = maxHeight;
            }
        }
        canvas.width = width;
        canvas.height = height;
        let context = canvas.getContext('2d');
        context?.drawImage(videoTag , 0, 0, width, height); 
        let data = canvas.toDataURL('image/jpeg',0.4); 
        data = data.replace("data:image/jpeg;base64,", "");

        return data;
    }

}

    export default VideoСapture;